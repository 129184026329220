import {
    Switch,
    Route
  } from "react-router-dom";

import ProjectCardA from './components/ProjectCardA';
import ProjectCardB from './components/ProjectCardB';
import NextProject from './components/NextProject';
import GustoPage from './GustoPage';
import AdidasPage from './AdidasPage';
import NintendoEventPage from './NintendoEventPage';
import NintendoKioskPage from './NintendoKioskPage';
import SeattleGlobalistPage from './SeattleGlobalistPage';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import GustoCover from './images/gusto/cover-transparent.png';
import GustoLogo from './images/gusto/gusto-logo.png';
import NintendoEventCover from './images/nintendo/cover-transparent.png';
import NintendoLogo from './images/nintendo/nintendo-logo.png';
import SeattleGlobalistCover from './images/seattleglobalist/cover-transparent.png';
import AdidasCover from './images/adidas/cover-transparent.png';
import NintendoKioskCover from './images/nintendo-kiosk/cover-transparent.png';


function HomePage() {

    return <Switch>
            <Route exact path="/">
                <Projects />
            </Route>
            <Route path="/gusto-tax-credits">
                <Gusto />
            </Route>
            <Route path="/nintendo-events">
                <NintendoEvents />
            </Route>
            <Route path="/nintendo-kiosk-app">
                <NintendoKiosk />
            </Route>
            <Route path="/adidas">
                <Adidas />
            </Route>
            <Route path="/seattleglobalist">
                <SeattleGlobalist />
            </Route>
        </Switch>
}

export default HomePage

function Projects() {
    const tooltip = (
        <Tooltip className="tooltip"><i class="fa-solid fa-link mr-1"></i>Click to copy my email</Tooltip>
      );

    return <div>
        <Row className="home-tagline">
            <Col lg={{ span: 8, offset: 2 }}  className="pb-5 d-flex justify-content-center text-center">
                <div>
                    <h2 className="pb-4"><strong>Rylie Sweem</strong> is a <strong>Product Designer</strong> who’s passionate about creating friendly, accessible, and data-driven digital experiences</h2>
                    <h5>currently designing tax credits experiences at <a href="https://gusto.com/" class="link link--animate" target="_blank" rel="noopener noreferrer">Gusto</a></h5>
                </div>
            </Col>
        </Row>
        <Row>
            <Col lg={{ span: 10, offset: 1 }}>
                <ProjectCardA 
                    link="/gusto-tax-credits"
                    image={GustoCover}
                    logo={GustoLogo}
                    title="Gusto tax credits"
                    subhead="I’ve designed 0-1 experiences that have allowed thousands of Gusto customers to easily uncover and claim R&D tax credits. I’ve focused on onboarding, upsells, credit fulfillment, and funnel orchestration. "
                    year="2021–present"
                /> 
            </Col>
        </Row>
        <Row>
            <Col lg={{ span: 10, offset: 1 }}>
                <ProjectCardB
                    link="/nintendo-kiosk-app"
                    image={NintendoKioskCover}
                    logo={NintendoLogo}
                    title="Nintendo kiosk companion app"
                    subhead="I worked with another designer to bring to life a companion app for a Nintendo kiosk experience. I designed, prototyped, and ran stakeholder meetings to see this project through."
                    year="2021"
                />
            </Col>
        </Row>
        <Row>
            <Col lg={{ span: 10, offset: 1 }}>
                <ProjectCardA
                    link="/nintendo-events"
                    image={NintendoEventCover}
                    logo={NintendoLogo}
                    title="Nintendo events management"
                    subhead="I redesigned an internal app for event management and publishing that was clunky and difficult to use. I did user research prior to design and ran 20 usability tests using a design prototype."
                    year="2020"
                />
            </Col>
        </Row>
        <Row>
            <Col lg={{ span: 10, offset: 1 }}>
                <ProjectCardB
                    link="/adidas"
                    image={AdidasCover}
                    title="Adidas shoe sole modeler"
                    subhead="I was one of two designers in an interdisciplinary capstone project sponsored by Adidas at the University of Washington. We created an internal tool for Adidas engineers to help target specific emotive responses of 3D printed lattices, which are used to develop sustainable shoe soles."
                    year="2021"
                />
            </Col>
        </Row>
        <Row>
            <Col lg={{ span: 10, offset: 1 }}>
                <ProjectCardA
                    link="/seattleglobalist"
                    image={SeattleGlobalistCover}
                    title="The Seattle Globalist redesign"
                    subhead="I developed branding guidelines and mobile mockups for a Seattle-based online application to bring them a new look that better aligned with their values and mission."
                    year="2021"
                />
            </Col>
        </Row>
        <Row className="d-none home-tagline">
            <Col lg={6} className="d-flex align-items-center">
                test
            </Col>
            <Col lg={6} className="d-flex align-items-center">
                <div>
                    <h5>Education</h5>
                    <h4>B.S. in Human Centered Design & Engineering</h4>
                    <p>I received a Bachelor’s degree in <strong>Human Centered Design & Engineering</strong> (HCDE) at the University of Washington. I studied the human-centered design process, qualitative and quantitative user research, interaction design, prototyping.</p>
                    <p>I also received a minor in <strong>Digital and Experimental Arts</strong> (DXARTS), where I mainly focused on the intersection between art and mechatronics.</p>
                </div>
            </Col>
        </Row>
        <Row className="pt-4 mt-4 section">
            <Col lg={{ span: 8, offset: 2 }}  className="pb-5 d-flex justify-content-center text-center">
                <div>
                    <h2 className="pb-4">Say hello!</h2>
                    <h5><a class=" link link--animate" href="https://www.linkedin.com/in/ryliesweem/" target="_blank" rel="noopener noreferrer">Connect with me on LinkedIn</a> or <OverlayTrigger placement="top" overlay={tooltip}><strong class=" link link--animate" onClick={() =>  navigator.clipboard.writeText('ryliesweem@gmail.com')}>email me</strong></OverlayTrigger>.</h5>
                </div>
            </Col>
        </Row>
    </div>;
}

function Gusto() {
    return <div>
        <GustoPage />
        <NextProject
            link="/nintendo-kiosk-app"
            image={NintendoKioskCover}
            title="Nintendo kiosk companion app"
        />
    </div>;
}

function NintendoKiosk() {
    return <div>
        <NintendoKioskPage />
        <NextProject
            link="/nintendo-events"
            image={NintendoEventCover}
            title="Nintendo events management"
        />
    </div>;
}

function NintendoEvents() {
    return <div>
        <NintendoEventPage />
        <NextProject
            link="/adidas"
            image={AdidasCover}
            title="Adidas shoe sole modeler"
        />
    </div>;
}

function Adidas() {
    return <div>
        <AdidasPage />
        <NextProject
        link="/seattleglobalist"
            image={SeattleGlobalistCover}
            title="The Seattle Globalist redesign"
        />
    </div>;
}

function SeattleGlobalist() {
    return <div>
        <SeattleGlobalistPage />
        <NextProject
            link="/gusto-tax-credits"
            image={GustoCover}
            title="Gusto tax credits"
        />
    </div>;
}