import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import NintendoLogo from './images/nintendo/nintendo-logo.png';
import NintendoCover from './images/nintendo/cover-transparent.png';
import Demo from './images/nintendo/wizard-demo.mp4';


function NintendoEventPage() {

    return <div>
        <Row>
            <Col lg className="d-flex align-items-center justify-content-center pb-3">
                <img src={NintendoLogo} alt='' className="card-logo"/>
            </Col>
        </Row>
        <Row>
            <Col lg className="d-flex align-items-center justify-content-center pb-3">
                <h1 className="text-center">Nintendo events management</h1>
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="d-flex align-items-center section">
                <img src={NintendoCover} className="rounded" alt='' />
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="section">
                <h2>Context</h2>
                <p>An existing admin tool that Nintendo employees use was unintuitive and difficult for new users trying to learn the system. Because of this, there is more work for the few who know the system well, creating a bottleneck in event management as a whole.</p>
                <p>I designed a user-friendly wizard for event creation and management that will accommodate new and infrequent users, allowing them to create events and make updates when they need them, rather than wait on a super user.</p>
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h2 className="pb-3">Video demo</h2>
            </Col>
        </Row>
        <Row>
            <Col lg={{ span: 10, offset: 1 }} className="section">
                <video controls playsInline muted src={Demo} />
            </Col>
        </Row>

    </div>  
}

export default NintendoEventPage