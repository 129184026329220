import React, { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import DarkMode from "./DarkMode";
import { LinkContainer } from 'react-router-bootstrap';

function NavigationBar() {
    const [key, setKey] = useState('1')

    return <Row className="main-nav">
        <Col md className="d-flex justify-content-md-start justify-content-center">
        <LinkContainer to="/">
            <Navbar.Brand className="" onClick={() => setKey('1')}>Rylie Sweem</Navbar.Brand>
        </LinkContainer>
        </Col>
        <Col md>
        <Navbar className="px-0 d-flex justify-content-md-end">
            <Nav onSelect={setKey} activeKey={key}>
                <LinkContainer to="/" exact activeClassName="active-link">
                    <Nav.Link className="link link--animate" eventKey="1">Work</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about" exact activeClassName="active-link">
                    <Nav.Link className="link link--animate" eventKey="2">About</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/resume" exact activeClassName="active-link">
                    <Nav.Link className="link link--animate" eventKey="3">Resume</Nav.Link>
                </LinkContainer>
                <DarkMode />
            </Nav>
        </Navbar>
        </Col>
        </Row>
}

export default NavigationBar