import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';

import AdidasCover from './images/adidas/cover-transparent.png';

import Annotated1 from './images/adidas/shoe-modeler-mockup1.png';
import Annotated2 from './images/adidas/shoe-modeler-mockup2.png';
import Annotated3 from './images/adidas/shoe-modeler-mockup3.png';
import Annotated4 from './images/adidas/shoe-modeler-mockup4.png';



function AdidasPage() {

    return <div>
        <Row>
            <Col lg className="d-flex align-items-center justify-content-center pb-3">
                <h1 className="text-center">Adidas shoe sole modeler</h1>
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="d-flex align-items-center section">
                <img src={AdidasCover} className="rounded" alt='' />
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="section">
                <h2>Context</h2>
                <p>In 2021, I had the privilege of being on an Adidas-sponsored capstone team at the University of Washington. The team was comprised of 3 Mechanical Engineering students and 2 Human Centered Design & Engineering students. </p>
                <p>The Adidas team was looking for a way to target specific emotive responses from athletes and connect these responses to mechanical properties. They wanted to know how the consumer would react or feel when picking up a certain shoe at the store. Adidas sponsored this Interdisciplinary Capstone Project to allow us to explore this problem space. Our goal was to design an internal application for designers and engineers that would allow them to create midsoles with emotive response in mind and explore emotive trends in lattices overall.</p>
                <p>Over the course of 5 months, I helped conduct user research with Adidas Engineers, collaborated with the engineering students to design and 3D print shoe sole lattice designs, used the 3D prints to run user testing and collect emotive response data, ran sketching exercises with the capstone team, presented lo-fi and hi-fi mockups to the Adidas team for feedback, and ultimately delivered a sweet Figma prototype with annotated mockups.</p>
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h2 className="pb-3">Gallery</h2>
            </Col>
        </Row>
        <Row>
            <Col lg={{ span: 10, offset: 1 }} className="section">
                <Carousel>
                    <Carousel.Item>
                        <img
                        className="d-block w-100 rounded"
                        src={Annotated1}
                        alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100 rounded"
                        src={Annotated2}
                        alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100 rounded"
                        src={Annotated3}
                        alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100 rounded"
                        src={Annotated4}
                        alt="Fourth slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>

    </div>  
}

export default AdidasPage