import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import GustoLogo from './images/gusto/gusto-logo.png';
import GustoCover from './images/gusto/cover-transparent.png';


function GustoPage() {

    return <div>
        <Row>
            <Col lg className="d-flex align-items-center justify-content-center pb-3">
                <img src={GustoLogo} alt='' className="card-logo"/>
            </Col>
        </Row>
        <Row>
            <Col lg className="d-flex align-items-center justify-content-center pb-3">
                <h1 className="text-center">Gusto tax credits</h1>
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="d-flex align-items-center section">
                <img src={GustoCover} className="rounded" alt='' />
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="section">
                <h2>Context</h2>
                <p>I joined Gusto in 2021 and helped design their first customer onboarding experience for SMBs to uncover and claim R&D tax credits. Since joining, I’ve led design to scale and improve the onboarding experience, which has allowed thousands of companies claim R&D tax credits. Additionally, I’ve worked on rebranding, upsell features, fulfillment experiences, credit substantiation, and so much more.</p>
                <strong>Comprehensive case studies available upon request!</strong>
            </Col>
        </Row>
    </div>
}

export default GustoPage