import React, { useEffect, useState } from "react";
import { FiSun } from 'react-icons/fi';
import { FaMoon } from 'react-icons/fa';

import Nav from 'react-bootstrap/Nav'


const DarkMode = () => {
  let clickedClass = "clicked"
  const body = document.body
  const lightTheme = "light"
  const darkTheme = "dark"
  const [theme, setTheme] = useState('')

  useEffect(()=>{
      const t = localStorage.getItem("theme")
      setTheme(t)
      if (t === lightTheme || t === darkTheme) {
        body.classList.add(t)
      } else {
        body.classList.add(lightTheme)
      }
    }, [body.classList])

  const switchTheme = e => {
      if (theme === darkTheme) {
          body.classList.replace(darkTheme, lightTheme)
          e.target.classList.remove(clickedClass)
          localStorage.setItem("theme", "light")
          setTheme(lightTheme)
      } else {
          body.classList.replace(lightTheme, darkTheme)
          e.target.classList.add(clickedClass)
          localStorage.setItem("theme", "dark")
          setTheme(darkTheme)
      }
    }

    function Icon(props) {
      if (props.theme === "") {
        return <FaMoon/>;
      }
      return <FiSun />;
    }

  return <Nav.Link
              className={"link link--animate px-2 darkMode" + (theme === "dark" ? clickedClass : "")}
              onClick={e => switchTheme(e)}>
              <i className={theme === "dark" ? "fas fa-cloud-sun d-none" : "fas fa-cloud-moon d-none"}></i>
              <Icon theme={theme === "dark" ? clickedClass : ""} /> 
          </Nav.Link>
}

export default DarkMode