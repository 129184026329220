import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Profile from './images/profile.png';

function AboutPage() {

    return <div>
        <Row>
            <Col lg={{ span: 6, offset: 1 }} className="pr-lg-5">
                <h2>I'm Rylie — nice to meet you!</h2>
                <p class="mb-3">I’m a product designer at <a href="https://gusto.com/"  target="_blank" rel="noopener noreferrer" class="link link--animate">Gusto</a>, primarily focused on client onboarding for tax credits. My team and I are developing tools that allow startups to claim R&D tax credits in a simple and efficient way.</p>
                <p class="mb-3">Before Gusto, I was a UX Designer at Nintendo of America focusing on their external events system and other enterprise apps. I also received my bachelor’s degree in  <a href="https://www.hcde.washington.edu/"  target="_blank" rel="noopener noreferrer" class="link link--animate">Human Centered Design & Engineering</a> with a minor in Digital & Experimental Arts at the University of Washington in Seattle.</p>
                <p class="mb-3">I am a big advocate for user-centered design and believe that thoughtful design can make or break a product. While I love iteration and prototyping in Figma, I also enjoy navigating user needs, business needs, and project constraints to deliver the best possible experience.</p>
                <p class="mb-3">In my free time, I love traveling and doing anything outdoors, like skiing, camping, and hiking, or taking my puppy on walks. I also enjoy cooking, baking, film photography, and spending time with my loved ones!</p>
                <p class="mb-3">You can find me on <a class=" link link--animate" href="https://www.linkedin.com/in/ryliesweem/" target="_blank" rel="noopener noreferrer">LinkedIn</a> if you’d like to get to know me more! </p>
            </Col>
            <Col lg={4} className="d-flex justify-content-center align-content-center">
                <div>
                    <img className="rounded" src={Profile} alt='Profile pic' />
                </div>
            </Col>
        </Row>
    </div>
}

export default AboutPage