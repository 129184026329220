import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer() {

    return <Row className="footer">
            <Col md className="footer-col justify-content-center justify-content-md-start">
                <p><a class=" link link--animate" href="https://www.linkedin.com/in/ryliesweem/" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-linkedin pr-2"></i>LinkedIn</a></p>
            </Col>
            <Col md className="footer-col justify-content-center">
                <p className="p-0"><i class="far fa-copyright" aria-hidden="true"></i>  {new Date().getFullYear()} Rylie Sweem</p>
            </Col>
            <Col md className="footer-col justify-content-center justify-content-md-end">
                <p>Made with <i class="fas fa-heart mx-1" aria-hidden="true"></i> and React</p>
            </Col>
    </Row> 
    
}

export default Footer