import React from 'react';

import ReactGA from 'react-ga';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import NavigationBar from './components/Navigation';
import ScrollToTop from './components/ScrollToTop';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import ResumePage from './ResumePage';
import Footer from './components/Footer';

import Container from 'react-bootstrap/Container';

function App() {
  ReactGA.initialize('UA-176420298-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Container fluid>

          <NavigationBar />
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/resume">
              <Resume />
            </Route>
            <Route>
              <Home />
            </Route>
          </Switch>
          
          <Footer />
        </Container> 
      </div>
    </Router>
  );
}

export default App;

function Home() {
  return <HomePage /> 
}

function About() {
  return <AboutPage />;
}

function Resume() {
  return <ResumePage />;
}
