import Col from 'react-bootstrap/Col';

import { HiOutlineDownload } from 'react-icons/hi';

import Resume from './images/resume.png';
import ResumePDF from './images/Rylie_Sweem_Resume.pdf';

function ResumePage() {

    return <div>
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <h2>My resume</h2>
            <button onClick={() => window.open(ResumePDF)}>
            <HiOutlineDownload className="mr-2"/>Download a PDF</button>
            <img className="mt-5 rounded" src={Resume} alt='my resume' />
        </Col>
    </div>
    
}

export default ResumePage