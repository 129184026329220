import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import NintendoLogo from './images/nintendo/nintendo-logo.png';
import NintendoKioskCover from './images/nintendo-kiosk/cover-transparent.png';
import Demo from './images/nintendo-kiosk/kiosk-demo.mp4';


function NintendoEventPage() {

    return <div>
        <Row>
            <Col lg className="d-flex align-items-center justify-content-center pb-3">
                <img src={NintendoLogo} alt='' className="card-logo"/>
            </Col>
        </Row>
        <Row>
            <Col lg className="d-flex align-items-center justify-content-center pb-3">
                <h1 className="text-center">Nintendo kiosk companion app</h1>
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="d-flex align-items-center section">
                <img src={NintendoKioskCover} className="rounded" alt='' />
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="section">
                <h2>Context</h2>
                <p>My team developed a companion app for a new game exploration kiosk experience that would allow for contactless interaction with the kiosk. The app was almost like a remote but with a few different features than what the kiosk had. During the early phases of this project, I assisted in the design for both app and kiosk screens. My team and I explored the interactions between both screens and created a prototype that would allow us to see it in action. Most of my contributions were focused on the sign in experience and the individual item pages. I also drove stakeholder meetings for feedback along the way.</p>
                <p>In 2023, the app launched and I tried it out in person in Best Buy!</p>
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h2 className="pb-3">Video demo</h2>
            </Col>
        </Row>
        <Row>
            <Col lg={{ span: 10, offset: 1 }} className="section">
                <video controls playsInline muted src={Demo} />
            </Col>
        </Row>
    </div>
}

export default NintendoEventPage