import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Cover from './images/seattleglobalist/cover-transparent.png';
import Mockup1 from './images/seattleglobalist/globalist-mockup1.png';
import Mockup2 from './images/seattleglobalist/globalist-mockup2.png';
import Mockup3 from './images/seattleglobalist/globalist-mockup3.png';
import Mockup4 from './images/seattleglobalist/globalist-mockup4.png';
import Mockup5 from './images/seattleglobalist/globalist-mockup5.png';
import Mockup6 from './images/seattleglobalist/globalist-mockup6.png';


function SeattleGlobalist() {

    return <div>
        <Row>
            <Col lg className="d-flex align-items-center justify-content-center pb-3">
                <h1 className="text-center">The Seattle Globalist redesign</h1>
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="d-flex align-items-center section">
                <img src={Cover} className="rounded" alt='' />
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="section">
                <h2>Context</h2>
                <p>The Seattle Globalist was a daily online publication based in Seattle. The organization was focused on highlighting local and global issues and facilitated community events such as journalism workshops and happy hours to spark continued discussion. Their website and branding was outdated and generic, so as an independant project to practice my graphic design skills, I refreshed the brand to better fit their vibe. I also created mockups to put the new brand in use.</p>
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h2 className="pb-3">Gallery</h2>
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }}>
                <Row>
                    <Col md={4} className="pb-4"><img src={Mockup1} className="rounded" alt='' /></Col>
                    <Col md={4} className="pb-4"><img src={Mockup2} className="rounded" alt='' /></Col>
                    <Col md={4} className="pb-4"><img src={Mockup3} className="rounded" alt='' /></Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }} className="section">
                <Row>
                    <Col md={4} className="pb-4"><img src={Mockup4} className="rounded" alt='' /></Col>
                    <Col md={4} className="pb-4"><img src={Mockup5} className="rounded" alt='' /></Col>
                    <Col md={4} className="pb-4"><img src={Mockup6} className="rounded" alt='' /></Col>
                </Row>
            </Col>
        </Row>
    </div> 
}

export default SeattleGlobalist