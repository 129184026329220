import {Link} from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ProjectCard(props) {

    return <Row className="proj-card">
        <Col lg={6} className=" d-lg-none mb-4 mb-lg-0">
            <Link to={props.link}>
                <img src={props.image} alt='' class="proj-card-img"/>
            </Link>
        </Col>   
        <Col lg={6} className="d-flex align-items-center">
            <Row>
                <Col className="proj-card-a-info">
                <img src={props.logo} alt='' className="card-logo"/>
                    <h2>{props.title}</h2>
                    <h6>{props.year}</h6>
                    <p>{props.subhead}</p>
                    <Link to={props.link}>
                        <button>Learn more</button>
                    </Link>
                </Col>
            </Row>
        </Col>  
        <Col lg={6} className="d-none d-lg-inline">
            <Link to={props.link}>
                <img src={props.image} alt='' class="proj-card-img "/>
            </Link>
        </Col>    
    </Row>
}

export default ProjectCard