import {Link} from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function NextProject(props) {

    return <Col lg={{ span: 10, offset: 1 }} className="section next-proj-bkg">
            <Row className="d-flex justify-content-center align-content-center">
                <Col md={6}>
                    <img src={props.image} className="rounded" alt='' />
                </Col>
                <Col md={6} className="p-5">
                    <strong>Next project</strong>
                    <h3 class="py-1">{props.title}</h3>
                    <Link to={props.link}>
                        <button>Learn more</button>
                    </Link>
                </Col>
            </Row>
    </Col>
}

export default NextProject